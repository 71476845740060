import React from "react"
import { Field, InjectedFormProps, WrappedFieldProps, reduxForm } from "redux-form"
import { Input, InputGroup, InputRightElement, Button } from "@chakra-ui/core"

const UiInput = (p: WrappedFieldProps) => (
  <Input
    value={p.input.value}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => p.input.onChange(event.target.value)}
    placeholder="Поиск по объявлениям..."
    type="text"
    pr="4.5rem"
    size="md"
  />
)

const SearchInput: React.StatelessComponent<InjectedFormProps> = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <InputGroup size="md">
      <Field name="query" component={UiInput} />
      <InputRightElement width="6rem">
        <Button leftIcon="search" h="1.75rem" size="sm" type="submit">
          Найти
        </Button>
      </InputRightElement>
    </InputGroup>
  </form>
)

const form = reduxForm({
  // a unique name for the form
  form: "searchInput",
})(SearchInput)

export default form
