import React from "react"
import { Box, Flex } from "@chakra-ui/core"
import LazyLoad from "react-lazyload"
import { connect } from "react-redux"
import YandexRtbLoader, { IAdvManager } from "./YandexRtbLoader"
import { IAppStore } from "reducers"

export enum BlockId {
  V2InSearchDesktop0 = `R-A-221884-5`,
  V2InSearchDesktop1 = `R-A-221884-6`,
  V2InSearchMobile0 = `R-A-221884-7`,
  V2InSearchMobile1 = `R-A-221884-8`,

  V2UnderSearchDesktop = `R-A-221884-9`,
  V2UnderSearchMobile = `R-A-221884-10`,

  V2LeftColumn = `R-A-221884-13`,
  V2RightColumn = `R-A-221884-12`,
}

interface IOwnProps {
  id: BlockId
  rerenderOnLocChange: boolean
  minHeightPx?: number
}

interface IStateProps {
  pageNumber: number
}

type Props = IOwnProps & IStateProps

class Block extends React.PureComponent<Props> {
  public render() {
    const testIframe = __DEV__ ? (
      <iframe
        width="100%"
        height={this.props.minHeightPx ? `${Math.round(this.props.minHeightPx / 2)}` : `400`}
        src="https://www.youtube.com/embed/Z2-SercltAQ"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    ) : null
    const content = __DEV__ ? (
      <Box>
        {this.props.minHeightPx ? (
          testIframe
        ) : (
          <>
            {testIframe}
            {testIframe}
          </>
        )}
      </Box>
    ) : (
      <Box>
        <div id={`yandex_rtb_${this.props.id}-page-${this.props.pageNumber}`}></div>
        <YandexRtbLoader onLoad={this.loadAds} />
      </Box>
    )

    // No box with max-height because RTB blocks can't be height-limited
    // if they are not fixed-size. Bu use min-height to minimize reflows.
    return this.props.minHeightPx ? (
      <Flex alignItems="center" justifyContent="center" minHeight={`${this.props.minHeightPx}px`}>
        {content}
      </Flex>
    ) : (
      <Box>{content}</Box>
    )
  }

  private loadAds = (AdvManager: IAdvManager) => {
    const opts = {
      blockId: this.props.id,
      renderTo: `yandex_rtb_${this.props.id}-page-${this.props.pageNumber}`,
      async: true,
    }
    console.info(`Yandex: render rtb`, opts)
    AdvManager.render(opts)
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.id !== prevProps.id || this.props.pageNumber !== prevProps.pageNumber) {
      console.info(`Yandex RTB block id=${this.props.id} was updated, refreshing ads`, prevProps, this.props)
      const w = window as any
      if (w && w.Ya && w.Ya.Context && w.Ya.Context.AdvManager) {
        this.loadAds(w.Ya.Context.AdvManager)
      } else {
        console.warn(`No yandex rtb AdvManager to erfresh ads`)
      }
    } else {
      console.info(`Yandex RTB block id=${this.props.id} was NOT updated, NOT refreshing ads`, prevProps, this.props)
    }
  }
}

const mapStateToProps = ({ rtb }: IAppStore): IStateProps => {
  return {
    pageNumber: rtb.pageNumber,
  }
}

const ConnectedBlock = connect<IStateProps, {}, IOwnProps>(mapStateToProps)(Block)

// Use lazyload not only because it's lazy but also because it doesn't render elements with hidden (display: none) parent.
// It saves us from unneeded requests when the block isn't visible.
export default (props: IOwnProps) => (
  <LazyLoad offset={200} height={props.minHeightPx}>
    <ConnectedBlock {...props} />
  </LazyLoad>
)
