/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { Box, Flex, Text, Heading } from "@chakra-ui/core"
import styled, { ITheme } from "modules/utils/theme"
import Link from "components/lib/Link"
import Button from "components/lib/Button"

interface IPageLinkParams {
  pageNumber?: number
  rel?: string
  name?: string
  disabled?: boolean
  active?: boolean
  isFirst?: boolean
  isLast?: boolean
  linkBuilder(page: number): string
}

const StyledText = styled(Text)`
  &:hover {
    background-color: ${(p) => p.theme.colors.blue[500]};
    color: ${(p) => p.theme.colors.white};
  }
`

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`

const PageLink: React.StatelessComponent<IPageLinkParams> = ({
  pageNumber,
  rel,
  name,
  disabled,
  active,
  linkBuilder,
  isFirst,
  isLast,
}: IPageLinkParams) => (
  <Box>
    <StyledLink rel={rel} href={linkBuilder(pageNumber)} isDisabled={disabled}>
      <StyledText
        css={(theme: ITheme) => ({
          display: `block`,
          padding: `${theme.space[2]}`,
          borderLeft: isFirst ? `${theme.borders[`1px`]} ${theme.colors.gray[200]}` : undefined,
          borderRight: `${theme.borders[`1px`]} ${theme.colors.gray[200]}`,
          borderTop: `${theme.borders[`1px`]} ${theme.colors.gray[200]}`,
          borderBottom: `${theme.borders[`1px`]} ${theme.colors.gray[200]}`,
          borderTopRightRadius: isLast ? theme.radii.md : theme.radii.none,
          borderBottomRightRadius: isLast ? theme.radii.md : theme.radii.none,
          borderBottomLeftRadius: isFirst ? theme.radii.md : theme.radii.none,
          borderTopLeftRadius: isFirst ? theme.radii.md : theme.radii.none,
          backgroundColor: active ? theme.colors.blue[400] : undefined,
          color: active ? theme.colors.white : disabled ? theme.colors.gray[400] : theme.colors.blue[400],
        })}
      >
        {name || pageNumber}
      </StyledText>
    </StyledLink>
  </Box>
)

export interface IPaginationParams {
  currentPageNumber: number
  lastPageNumber: number
  linkBuilder?(page: number): string
}

function* range(begin: number, end: number, interval: number = 1): Iterable<number> {
  for (let i = begin; i <= end; i += interval) {
    yield i
  }
}

function pagesRel(currentPageNumber: number, p: number): string {
  switch (true) {
    case p === currentPageNumber - 1:
      return "prev"
    case p === currentPageNumber + 1:
      return "next"
    default:
      return ""
  }
}

const DesktopPagination = ({ currentPageNumber, lastPageNumber, linkBuilder }: IPaginationParams) => {
  if (lastPageNumber === 0) {
    return null
  }

  const pages: IPageLinkParams[] = []
  pages.push({ linkBuilder, pageNumber: 1, name: "« Первая", disabled: currentPageNumber === 1 })
  pages.push({ linkBuilder, rel: "prev", name: "‹ Предыдущая", pageNumber: currentPageNumber - 1, disabled: currentPageNumber === 1 })

  const pagesWindow = 3
  const pageFrom = Math.max(currentPageNumber - pagesWindow, 1)
  const pageTo = Math.min(currentPageNumber + pagesWindow, lastPageNumber)

  if (pageFrom > 1) {
    pages.push({ linkBuilder, name: "...", disabled: true })
  }

  for (const p of range(pageFrom, pageTo)) {
    pages.push({ linkBuilder, rel: pagesRel(currentPageNumber, p), pageNumber: p, active: p === currentPageNumber })
  }

  if (pageTo < lastPageNumber) {
    pages.push({ linkBuilder, name: "...", disabled: true })
  }

  pages.push({
    linkBuilder,
    rel: "next",
    name: "Следующая ›",
    pageNumber: currentPageNumber + 1,
    disabled: currentPageNumber === lastPageNumber,
  })
  pages.push({ linkBuilder, pageNumber: lastPageNumber, name: "Последняя »", disabled: currentPageNumber === lastPageNumber })

  return (
    <Flex>
      {pages.map((p, i) => (
        <PageLink key={i} {...p} isFirst={i === 0} isLast={i === pages.length - 1} />
      ))}
    </Flex>
  )
}

const MobilePagination = ({ currentPageNumber, lastPageNumber, linkBuilder }: IPaginationParams) => {
  if (lastPageNumber === 0) {
    return null
  }

  return (
    <Box marginBottom={3}>
      <nav aria-label="mobile-pagination">
        <Heading as="h3" marginBottom={2}>
          Страница №{currentPageNumber}
        </Heading>
        <Button
          leftIcon="arrow-back"
          marginRight={6}
          rel="prev"
          href={linkBuilder(currentPageNumber - 1)}
          isDisabled={currentPageNumber === 1}
          size="sm"
        >
          Предыдущие
        </Button>
        <Button
          rightIcon="arrow-forward"
          rel="next"
          href={linkBuilder(currentPageNumber + 1)}
          isDisabled={currentPageNumber === lastPageNumber}
          size="sm"
        >
          Следующие
        </Button>
      </nav>
    </Box>
  )
}

const Pagination = (props: IPaginationParams) => (
  <Box>
    <Box display={{ base: `none`, md: `block` }}>
      <DesktopPagination {...props} />
    </Box>
    <Box display={{ base: `block`, md: `none` }}>
      <MobilePagination {...props} />
    </Box>
  </Box>
)

export default Pagination
