import React from "react"
import { AdsList, IAdsListProps, IAdInListProps } from "../partials/AdsList"
import SearchInput from "../filters/SearchInput"
import SearchRadiusSelector from "../filters/SearchRadiusSelector"
import CategoryFilter from "components/filters/CategoryFilter"
import PlaceSelector from "../filters/PlaceSelector"
import Pagination, { IPaginationParams } from "components/lib/Pagination"
import VkCommentsBrowser from "components/lib/VkCommentsBrowser"
import YandexRtbBlock, { BlockId } from "components/lib/YandexRtbBlock"
import { connect } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import { IAppStore } from "reducers"
import { push, Push } from "connected-react-router"
import { adsPageFetch, adsIncrementCounter, inverseFiltersCollapsion } from "modules/ads/ads.actions"
import { IAdsPageParams, IAd, parseParamsFromUrl } from "modules/ads/ads.reducers"
import { getUrlWithParamsWoPage, getUrlWithParams } from "modules/ads/ads.sagas"
import { ContentHeader } from "../partials/ContentHeader"
import { makePetCardProps } from "../partials/PetCard"
import { ICategoryProps } from "components/lib/CategoryLink"
import deepEqual from "deep-equal"
import { Helmet } from "react-helmet"
import Sticky from "react-stickynode"
import { Box, Flex, Collapse } from "@chakra-ui/core"
import Button from "components/lib/Button"
import { sendAmplitudeEvent } from "modules/utils/events"
import { parseISO } from "date-fns"
import Link from "components/lib/Link"

const defaultSearchRadiusKm = 20

interface IAdsIndexStateProps extends IAdsListProps, IPaginationParams {
  isFetching?: boolean
  pageParams: IAdsPageParams
  areFiltersCollapsed: boolean
  createdAdId?: number
}

interface IAdsIndexOwnProps {
  isFromHomePage?: boolean
}

export interface IAdsIndexProps extends IAdsIndexOwnProps, IAdsIndexStateProps, IAdsIndexDispatchProps, RouteComponentProps<void> {}

interface IAdsIndexDispatchProps {
  adsPageFetch(p: IAdsPageParams): void
  push: Push
  adsIncrementCounter(ids: number[], counterType: string): void
  inverseFiltersCollapsion(): void
}

const fetchingAds: IAdInListProps[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_) => ({} as any as IAdInListProps))

interface IPageMeta {
  title: string
  description: string
}

const sendLoadedEvent = (pageParams: IAdsPageParams, loadedAds: IAdInListProps[]) => {
  if (__CLIENT__) {
    const eventProps: any = {
      place: pageParams.place || "",
      category: pageParams.category || "",
      animalType: pageParams.animalType || "",
      page: pageParams.page || "",
      query: pageParams.query || "",
      searchRadius: pageParams.searchRadius || 0,
      finishedFilter: pageParams.finishedFilter || "",
      sourceFilter: pageParams.sourceFilter || "",
      adCount: loadedAds.length,
    }
    if (loadedAds.length && pageParams.page === 1) {
      const now = new Date()
      const firstAdCreatedAt = parseISO(loadedAds[0].createdAt)
      const elapsedMinutes = Math.round((now.getTime() - firstAdCreatedAt.getTime()) / 1000 / 60)
      eventProps.minutesSinceNewestAd = elapsedMinutes
    }
    sendAmplitudeEvent("list_ads", eventProps)
  }
}

// Use shouldComonentUpdate => don't use React.PureComponent
class AdsIndexComponent extends React.Component<IAdsIndexProps> {
  constructor(props: IAdsIndexProps) {
    super(props)

    if (this.props.ads) {
      // SSR-ed hydrated page on a client.
      if (this.props.ads && this.props.ads.length) {
        if (!this.props.isFromHomePage) {
          sendLoadedEvent(this.props.pageParams, this.props.ads)
        }

        // TODO: use pure component and move it into backend
        // ads index page changed: ads changed
        this.props.adsIncrementCounter(
          this.props.ads.map((ad) => ad.id),
          "view"
        )
      }
    } else {
      // Server-side execution or client-side execution without hydration.
      // Run ads loading from constructor because componentDidMount isn't called in SSR
      // TODO: use https://reactjs.org/docs/concurrent-mode-suspense.html
      this.tryLoadAds(this.props)
    }
  }

  private tryLoadAds(props: IAdsIndexProps) {
    if (!props.ads) {
      this.props.adsPageFetch(props.pageParams)
    }
  }

  public componentWillReceiveProps(nextProps: IAdsIndexProps) {
    if (!deepEqual(this.props.pageParams, nextProps.pageParams)) {
      this.tryLoadAds(nextProps)
    }
  }

  public componentDidUpdate(prevProps: IAdsIndexProps): void {
    // Use (!prevProps.ads && this.props.ads) instead of componentDidMount because
    // in componentDidMount we have no ads (if no SSR hydration).

    if (!deepEqual(this.props.pageParams, prevProps.pageParams) || (!prevProps.ads && this.props.ads)) {
      // At least isFetching changes => compare pageParams

      if (this.props.ads && this.props.ads.length) {
        if (!this.props.isFromHomePage) {
          sendLoadedEvent(this.props.pageParams, this.props.ads)
        }

        // TODO: use pure component and move it into backend
        // ads index page changed: ads changed
        this.props.adsIncrementCounter(
          this.props.ads.map((ad) => ad.id),
          "view"
        )
      }
    }
  }

  public shouldComponentUpdate(nextProps: IAdsIndexProps): boolean {
    return !deepEqual(this.props, nextProps)
  }

  private urlWoPageWithOverride(override: object): string {
    return getUrlWithParamsWoPage({ ...this.props.pageParams, ...override })
  }

  private linkToCategory = (cat: string): string => {
    return this.urlWoPageWithOverride({ category: cat })
  }

  private linkToAnimalType = (at: string): string => {
    return this.urlWoPageWithOverride({ animalType: at })
  }

  private linkToPlace = (place: string): string => {
    return this.urlWoPageWithOverride({ place })
  }

  private linkToPage(page: number): string {
    return getUrlWithParams({ ...this.props.pageParams, page })
  }

  private goToUrlWoPageWithOverride(override: object): void {
    this.props.push(this.urlWoPageWithOverride(override))
  }

  private onPlaceChange = (place: string): void => {
    this.goToUrlWoPageWithOverride({ place })
  }

  private onSearchRadiusChange = (newValue: number): void => {
    this.goToUrlWoPageWithOverride({ searchRadius: newValue })
  }

  private onSearch = (values: any) => {
    this.goToUrlWoPageWithOverride({ query: values.query })
  }

  private renderFooter(meta: IPageMeta) {
    return (
      <Box marginTop={3}>
        {/* <hr />
        <Box display={{ base: `none`, md: `block` }}>
          <YandexRtbBlock minHeightPx={300} id={BlockId.V2UnderSearchDesktop} rerenderOnLocChange={true} />
        </Box>
        <Box display={{ base: `block`, md: `none` }}>
          <YandexRtbBlock minHeightPx={300} id={BlockId.V2UnderSearchMobile} rerenderOnLocChange={true} />
        </Box> */}
        <hr />

        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
      </Box>
    )
  }

  private renderLeftColumn(currentPlace: string, curCategory: string, curAnimalType: string, categories: string[], animalTypes: string[]) {
    // video rtb requires 320px minimum, so can't use padding
    return (
      <Box marginTop={2}>
        <PlaceSelector onChange={this.onPlaceChange} currentPlace={currentPlace} linkBuilder={this.linkToPlace} />
        {currentPlace && currentPlace !== "Все города" && (
          <CategoryFilter name="Категории" currentCategory={curCategory} categories={categories} linkBuilder={this.linkToCategory} />
        )}
        {curCategory && (
          <CategoryFilter name="Кто" currentCategory={curAnimalType} categories={animalTypes} linkBuilder={this.linkToAnimalType} />
        )}
        {currentPlace && currentPlace !== "Все города" && (
          <SearchRadiusSelector
            onChange={this.onSearchRadiusChange}
            currentValue={this.props.pageParams.searchRadius || defaultSearchRadiusKm}
          />
        )}

        <Box shadow="sm" paddingTop={2} minHeight="600px">
          <VkCommentsBrowser maxHeight={600} />
        </Box>

        {/* {__CLIENT__ && (
          <Box shadow="sm" maxWidth="320px" minHeight="1470px" marginTop={2}>
            <Sticky bottomBoundary="#ads-index-primary-content">
              <YandexRtbBlock id={BlockId.V2LeftColumn} rerenderOnLocChange={true} />
            </Sticky>
          </Box>
        )} */}
      </Box>
    )
  }

  private renderMobileFilters(
    currentPlace: string,
    curCategory: string,
    curAnimalType: string,
    categories: string[],
    animalTypes: string[]
  ) {
    return (
      <Box marginBottom={2}>
        <Flex>
          <Button onClick={this.props.inverseFiltersCollapsion} size="sm">
            {this.props.areFiltersCollapsed ? "Показать фильтры" : "Скрыть фильтры"}
          </Button>
          {!this.props.createdAdId && (
            <Button variantColor="pink" marginLeft={4} href="/ads/new" size="sm">
              Создать объявление
            </Button>
          )}
        </Flex>
        <Collapse isOpen={!this.props.areFiltersCollapsed}>
          <PlaceSelector onChange={this.onPlaceChange} currentPlace={currentPlace} linkBuilder={this.linkToPlace} />
          {currentPlace && currentPlace !== "Все города" && (
            <CategoryFilter name="Категории" currentCategory={curCategory} categories={categories} linkBuilder={this.linkToCategory} />
          )}
          {curCategory && (
            <CategoryFilter name="Кто" currentCategory={curAnimalType} categories={animalTypes} linkBuilder={this.linkToAnimalType} />
          )}
          {currentPlace && currentPlace !== "Все города" && (
            <SearchRadiusSelector
              onChange={this.onSearchRadiusChange}
              currentValue={this.props.pageParams.searchRadius || defaultSearchRadiusKm}
            />
          )}
        </Collapse>
      </Box>
    )
  }

  private renderPrimaryColumn(
    pagination: JSX.Element,
    allAdsButton: JSX.Element,
    currentPlace: string,
    curCategory: string,
    curAnimalType: string,
    categories: string[],
    animalTypes: string[]
  ) {
    return (
      <Box paddingTop={3} paddingLeft={3} paddingRight={3}>
        <Box display={{ base: `block`, md: `block`, lg: `none` }}>
          {this.renderMobileFilters(currentPlace, curCategory, curAnimalType, categories, animalTypes)}
        </Box>

        <Box>
          <SearchInput onSubmit={this.onSearch} initialValues={this.props.pageParams} />
        </Box>

        <Box marginTop={2}>
          <Box marginBottom={3}>{pagination}</Box>
          <AdsList ads={this.props.ads ? this.props.ads : fetchingAds} isFetching={!this.props.ads || this.props.isFetching} />
          {pagination}
          {allAdsButton}
        </Box>
      </Box>
    )
  }

  private goToAllAds = () => this.props.push(`/Все города`)

  private buildPageMeta(
    isHome: boolean,
    pageParams: IAdsPageParams,
    currentPlace: string,
    curCategory?: string,
    curAnimalType?: string
  ): IPageMeta {
    if (isHome) {
      return {
        title: `Потеряшки, пропавшие и найденные собаки и животные`,
        description: `Объявления о потерянных и найденных собаках, кошках и других животных. Потеряшки во всех городах. Большая база объявлений и удобный поиск.`,
      }
    }

    let categoryTitleName: string
    switch (curCategory) {
      case "Пропала":
        categoryTitleName = "Пропавшие"
        break
      case "Найдена":
        categoryTitleName = "Найденные"
        break
    }

    let animalTitleName: string
    switch (curAnimalType) {
      case "Собака":
        animalTitleName = "собаки"
        break
      case "Кошка":
        animalTitleName = "кошки"
        break
    }

    // TODO: fetch ad count in current city and add it into description.

    let title: string
    let description: string
    switch (true) {
      case Boolean(curAnimalType):
        title = `Потеряшки. ${currentPlace}. ${categoryTitleName} ${animalTitleName}`
        description = `Потеряшки, ${currentPlace}. ${categoryTitleName} ${animalTitleName}`
        break
      case Boolean(curCategory):
        title = `Потеряшки. ${currentPlace}. ${categoryTitleName} собаки и животные`
        description = `Потеряшки, ${currentPlace}. ${categoryTitleName} собаки и животные`
        break
      default:
        title = `Потеряшки. ${currentPlace}. Пропавшие и найденные собаки и животные`
        description = `Потеряшки, ${currentPlace}. Пропавшие и найденные собаки и животные`
        break
    }

    if (pageParams.finishedFilter === `yes`) {
      title += `. Завершенные поиски`
      description += `. Завершенные поиски`
    }
    description += `. Большая база объявлений и удобный поиск`

    return {
      title,
      description,
    }
  }

  public render() {
    const { pageParams } = this.props

    const currentPlace = pageParams.place

    const curCategory = pageParams.category
    const categories = ["Пропала", "Найдена"]

    const curAnimalType = pageParams.animalType
    const animalTypes = ["Собака", "Кошка"]

    const breadcrumbs: ICategoryProps[] = [
      { name: currentPlace, url: `/${currentPlace}` },
      curCategory ? { name: curCategory, url: `/${currentPlace}/${curCategory}` } : null,
      curAnimalType ? { name: curAnimalType, url: `/${currentPlace}/${curCategory}/${curAnimalType}` } : null,
    ].filter((b) => b)

    const isHome = this.props.location.pathname === "/"
    const pageMeta = this.buildPageMeta(isHome, pageParams, currentPlace, curCategory, curAnimalType)

    const pagination = isHome ? null : (
      <Pagination
        currentPageNumber={this.props.currentPageNumber}
        lastPageNumber={this.props.lastPageNumber}
        linkBuilder={this.linkToPage.bind(this)}
      />
    )

    const allAdsButton = isHome ? <Button onClick={this.goToAllAds}>Все объявления</Button> : null

    const ret = (
      <Box>
        <Box id="ads-index-primary-content">
          {this.props.location.pathname !== "/" && <ContentHeader breadcrumbs={breadcrumbs} title={pageMeta.title} />}
          <Flex>
            <Box marginLeft={2} minWidth="320px" display={{ base: `none`, md: `none`, lg: `block` }}>
              {this.renderLeftColumn(currentPlace, curCategory, curAnimalType, categories, animalTypes)}
            </Box>
            <Box maxWidth={{ base: `100%`, md: `100%`, lg: `calc(100% - 320px)` }}>
              {this.renderPrimaryColumn(pagination, allAdsButton, currentPlace, curCategory, curAnimalType, categories, animalTypes)}
            </Box>
          </Flex>
        </Box>

        {this.renderFooter(pageMeta)}
      </Box>
    )
    return ret
  }
}

const makeAdInListProps = (ad: IAd, params: IAdsPageParams): IAdInListProps => {
  return { ...makePetCardProps(ad, ad.nearestPlaceName || params.place), id: ad.id }
}

const mapStateToProps = ({ ads }: IAppStore, routeProps: RouteComponentProps<void>): IAdsIndexStateProps => {
  const pageParams = parseParamsFromUrl(routeProps.location.pathname, routeProps.location.search)
  const locationPages = ads ? ads.index[getUrlWithParamsWoPage(pageParams)] : null
  const adIds = locationPages ? locationPages[pageParams.page] : null
  return {
    ads: adIds ? adIds.map((id) => makeAdInListProps(ads.storage[id], pageParams)) : null,
    currentPageNumber: pageParams.page,
    lastPageNumber: ads.pagination.lastPageNumber,
    isFetching: ads.isFetching,
    pageParams,
    areFiltersCollapsed: ads.areFiltersCollapsed,
    createdAdId: ads.session.createdAdId,
  }
}

const mapDispatchToProps: IAdsIndexDispatchProps = {
  adsPageFetch,
  push,
  adsIncrementCounter,
  inverseFiltersCollapsion,
}

export default withRouter(
  connect<IAdsIndexStateProps, IAdsIndexDispatchProps, IAdsIndexOwnProps>(mapStateToProps, mapDispatchToProps)(AdsIndexComponent)
)
