import React from "react"
import Link from "components/lib/Link"
import { Box, Heading, Flex, Text } from "@chakra-ui/core"

interface ICategoryFilterProps {
  currentCategory?: string
  categories: string[]
  linkBuilder(cat: string): string
  name: string
}

const CategoryFilter = ({ categories, currentCategory, linkBuilder, name }: ICategoryFilterProps) => (
  <Box boxShadow="sm" padding={2}>
    <Heading as="h3" size="md">
      {name}
    </Heading>
    <Flex marginTop={2}>
      {categories.map((c, i) =>
        currentCategory && currentCategory === c ? (
          <Text as="b" marginLeft={i === 0 ? 0 : 3} key={i}>
            {c}
          </Text>
        ) : (
          <Link marginLeft={i === 0 ? 0 : 3} key={i} href={linkBuilder(c)}>
            {c}
          </Link>
        )
      )}
    </Flex>
  </Box>
)

export default CategoryFilter
