import React from "react"
import { Box, Heading, Skeleton, Divider } from "@chakra-ui/core"
import PetCard, { IPetCardProps } from "./PetCard"
import Link from "components/lib/Link"
import YandexRtbBlock, { BlockId } from "components/lib/YandexRtbBlock"
import styled from "modules/utils/theme"

export interface IAdInListProps extends IPetCardProps {
  id: number
}

export interface IAdsListProps {
  ads: IAdInListProps[]
  isFetching?: boolean
}

function truncateDescription(ad: IAdInListProps): string {
  if (ad.description.length <= 200) {
    return ad.description
  }

  return ad.description.substring(0, 200) + "..."
}

function prepareAdToListing(ad: IAdInListProps): IAdInListProps {
  const ret: IAdInListProps = { ...ad }
  ret.description = truncateDescription(ret)
  return ret
}

const Loader = () => (
  <>
    <Divider />
    <Skeleton height="20px" width="50%" marginTop={2} />
    <Skeleton height="20px" width="70%" marginTop={2} />
    <Skeleton height="20px" width="60%" marginTop={2} />
  </>
)

const HeadingLink = styled(Link)`
  box-shadow: none;
`

export class AdsList extends React.Component<IAdsListProps> {
  private renderAdsense(i: number) {
    if (i !== 4 && i !== 8) {
      return null
    }

    const block0 = (
      <>
        <Box display={{ base: `none`, md: `block` }}>
          <YandexRtbBlock minHeightPx={300} id={BlockId.V2InSearchDesktop0} rerenderOnLocChange={true} />
        </Box>
        <Box display={{ base: `block`, md: `none` }}>
          <YandexRtbBlock minHeightPx={300} id={BlockId.V2InSearchMobile0} rerenderOnLocChange={true} />
        </Box>
      </>
    )

    const block1 = (
      <>
        <Box display={{ base: `none`, md: `block` }}>
          <YandexRtbBlock minHeightPx={300} id={BlockId.V2InSearchDesktop1} rerenderOnLocChange={true} />
        </Box>
        <Box display={{ base: `block`, md: `none` }}>
          <YandexRtbBlock minHeightPx={300} id={BlockId.V2InSearchMobile1} rerenderOnLocChange={true} />
        </Box>
      </>
    )
    return (
      <Box bg="gray" boxShadow="md" padding={2}>
        {i === 4 && block0}
        {i === 8 && block1}
      </Box>
    )
  }

  private renderAd = (ad: IAdInListProps, i: number) => {
    return (
      <Box key={i} marginBottom={2}>
        {/* {this.renderAdsense(i)} */}
        {this.props.isFetching ? (
          <Loader />
        ) : (
          <Box bg="gray" boxShadow="md" padding={2}>
            <Heading as="h3" size="md" marginBottom={2}>
              <HeadingLink href={`/ads/${ad.id}`}>{ad.title}</HeadingLink>
            </Heading>

            <PetCard {...ad}
              short
              isLazy={i !== 0} // first ad image is always visible. It's especially critical for mobile web: google web vitals shows this as critical recommendation.
            />
          </Box>
        )}
      </Box>
    )
  }

  public render() {
    if (!this.props.ads.length) {
      return <h3>Объявлений не найдено</h3>
    }
    const ads = this.props.isFetching ? this.props.ads : this.props.ads.map(prepareAdToListing)
    return <Box>{ads.map(this.renderAd)}</Box>
  }
}
