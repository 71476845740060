import React from "react"
import { Box, Heading, Flex, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb } from "@chakra-ui/core"
import { FaArrowsAltH } from "react-icons/fa"

interface IProps {
  currentValue: number
  onChange: (newValue: number) => void
}

const SearchRadiusSelector = ({ currentValue, onChange }: IProps) => (
  <Box boxShadow="sm" padding={2}>
    <Flex justify="space-between">
      <Heading as="h3" size="md">
        Радиус поиска
      </Heading>
      <Text>{currentValue} км</Text>
    </Flex>
    <Box marginTop={2}>
      <Slider color="teal" value={currentValue} min={1} max={100} onChange={onChange}>
        <SliderTrack />
        <SliderFilledTrack />
        <SliderThumb color="pink" size={6}>
          <FaArrowsAltH color="#4DAF7C" />
        </SliderThumb>
      </Slider>
    </Box>
  </Box>
)

export default SearchRadiusSelector
