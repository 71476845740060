import React from "react"
import { Heading, Box, Text, Flex } from "@chakra-ui/core"
import { IAppStore } from "reducers"
import { nearPlacesFetch } from "modules/places/places.actions"
import { connect } from "react-redux"
import getLoader from "modules/utils/loader"
import Link from "components/lib/Link"

import "react-select/dist/react-select.css"
import "react-virtualized/styles.css"
import "react-virtualized-select/styles.css"

// Then import the virtualized Select HOC
import VirtualizedSelect from "react-virtualized-select"

interface IPlaceSelectorOwnProps {
  currentPlace: string
  onChange(place: string): void
  linkBuilder(place: string): string
}

interface IPlaceSelectorStateProps {
  nearPlaces: string[]
}

interface IPlaceSelectorDispatchProps {
  nearPlacesFetch(): void
}

interface IPlaceSelectorProps extends IPlaceSelectorOwnProps, IPlaceSelectorStateProps, IPlaceSelectorDispatchProps {}

class PlaceSelector extends React.Component<IPlaceSelectorProps> {
  constructor(props: IPlaceSelectorProps) {
    super(props)

    // Run it from constructor because componentDidMount isn't called in SSR
    // TODO: use https://reactjs.org/docs/concurrent-mode-suspense.html
    if (!props.nearPlaces) {
      props.nearPlacesFetch()
    }
  }

  private renderSelector() {
    const { currentPlace, nearPlaces, onChange, linkBuilder } = this.props
    const options = nearPlaces ? nearPlaces.filter((p) => p !== currentPlace).map((p) => ({ label: p, value: p })) : null
    // TODO: use another select library because react-virtualized-select isn't supported and is old - can't properly set up z-index.
    // Because of z-index select on mobile screens clashes with search box and radius slider.
    return (
      <Box>
        <Box display={{ base: `none`, md: `block` }}>
          <Flex marginTop={2}>
            <Text as="b">{currentPlace}</Text>
            {nearPlaces
              .slice(0, 3)
              .filter((p) => p !== currentPlace)
              .slice(0, 2) // slice-filter-slice to always have 3 elements
              .map((p, i) => (
                <Link marginLeft={3} href={linkBuilder(p)} key={i}>
                  {p}
                </Link>
              ))}
          </Flex>
        </Box>
        <Box marginTop={2}>
          <VirtualizedSelect onChange={(o: any) => onChange(o.value)} placeholder="Другой город" options={options} />
        </Box>
      </Box>
    )
  }

  public render() {
    const { currentPlace, nearPlaces } = this.props
    return (
      <Box boxShadow="sm" padding={2}>
        <Box display={{ base: `none`, md: `block` }}>
          <Heading as="h3" size="md">
            Где искать
          </Heading>
        </Box>
        <Box display={{ base: `block`, md: `none` }}>
          <Heading as="h3" size="md">{`Где искать - ${currentPlace}`}</Heading>
        </Box>
        {nearPlaces ? this.renderSelector() : getLoader()}
      </Box>
    )
  }
}

const mapStateToProps = ({ places }: IAppStore): IPlaceSelectorStateProps => {
  return {
    nearPlaces: places && places.near && places.near.length ? places.near : null,
  }
}

const mapDispatchToProps: IPlaceSelectorDispatchProps = {
  nearPlacesFetch,
}

export default connect<IPlaceSelectorStateProps, IPlaceSelectorDispatchProps, IPlaceSelectorOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(PlaceSelector)
