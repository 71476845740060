import React from "react"
import Script from "react-load-script"

interface IRenderOpts {
  blockId: string
  renderTo: string
  async: boolean
}

export interface IAdvManager {
  render: (opts: IRenderOpts) => void
}

interface IProps {
  onLoad: (AdvManager: IAdvManager) => void
}

const scriptAddr = [`//a`, `n.y`, `and`, `ex.r`, `u/s`, `yst`, `em/c`, `ont`, `ex`, `t.j`, `s`].join(``)

const YandexRtbLoader = React.memo(({ onLoad }: IProps) => {
  if (typeof window === "undefined") {
    return
  }

  return (
    <Script
      url={scriptAddr}
      onError={() => console.error(`failed to load yandex rtb script`)}
      onLoad={() => {
        console.info(`loaded yandex rtb script`)
        const w = window as any
        if (w.Ya && w.Ya.Context && w.Ya.Context.AdvManager) {
          onLoad(w.Ya.Context.AdvManager)
        } else {
          console.warn("YandexRtbLoader: on load: no yandex advmanager object")
        }
      }}
      attributes={{ async: false, defer: true }}
    />
  )
})

export default YandexRtbLoader
